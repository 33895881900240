
import { FilterType, DataType } from '@colven/common-domain-lib/lib'
/**
 * Constantes para las configuraciones
 */
const ConfigurationKeys = {
    PINNED_FRONTS_KEY: 'smart-truck-dispatcher.pinned-fronts',
    FRONTS_ORDER_KEY: 'smart-truck-dispatcher.fronts-order',
    SELECTED_FRONTS_KEY: 'smart-truck-dispatcher-Selected-fronts',
}
const Orders = {
    NAME_ASCENDING: 'name_ascending',
    NAME_DESCENDING: 'name_descending',
    CATEGORY_ASCENDING: 'category_ascending',
    CATEGORY_DESCENDING: 'category_descending',
}
const STATUS_ESPERA_VACIO ='ESPERA_VACIO'
const HARVEST_FRONT_DEVIATION_TIMES_KEY=  'deviation-times'
const OutOfConnectionFrontsReasons=
{
    OUT_OF_CONNECTION: 'smartTruckDispatcher.OutOfConnectionFrontsReasons.OutOfConnection',
    NOT_CONFIGURED_FRONT:'smartTruckDispatcher.OutOfConnectionFrontsReasons.NotConfiguredFront',
    NOT_DRIVE_UNIT:'smartTruckDispatcher.OutOfConnectionFrontsReasons.NotConfiguredDriveUnit',
    NOT_DEFAULT_EMPTY_TRIP_TIME:'smartTruckDispatcher.OutOfConnectionFrontsReasons.NotConfiguredDefaultEmptyTripTime',
    NOT_DEFAULT_FULL_TRIP_TIME:'smartTruckDispatcher.OutOfConnectionFrontsReasons.NotConfiguredDefaultFullTripTime',
    CONNECTED: 'smartTruckDispatcher.OutOfConnectionFrontsReasons.Connected',
}
const NOT_UM_MESSAGE= 'smartTruckDispatcher.NotUMMessage';

const NO_CATEGORY_ID ='NO_CATEGORY';
const NO_CATEGORY_NAME ='category.noCategory';


const trucksHeaders = (i18n) => {
    return [       
        {

            text: i18n.t('smartTruckDispatcher.headers.name'),
            type: DataType.string,
            align: 'left',
            sortable: true,
            value: 'thingName',
            selected: true,
            filterType: FilterType.textField,
        },
        {

            text: i18n.t('smartTruckDispatcher.headers.identificator'),
            type: DataType.string,
            align: 'left',
            sortable: true,
            value: 'identificator',
            selected: true,
            filterType: FilterType.textField,
        },
    ];
}

export default {
    ConfigurationKeys,
    Orders,
    HARVEST_FRONT_DEVIATION_TIMES_KEY,
    STATUS_ESPERA_VACIO,
    NO_CATEGORY_ID,
    NO_CATEGORY_NAME,
    OutOfConnectionFrontsReasons,
    NOT_UM_MESSAGE,
    trucksHeaders
}
