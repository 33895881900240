// import i18n from '@/i18n'
import { SnackbarStyle } from '@/constants/constants'
import { mapMutations, mapActions } from 'vuex'
import TableConstants from '@/constants/smartTruckDispatcher.constants'
import i18n from '@/i18n'


export default {
  name: 'TruckSelectorComponent',
  components: {
  },
  props: {
    trucks:undefined,
  },
  data: () => ({
    tableDataKey: '_id',
    tableHeaders: [],
    tableFilterRules: {},
    tableData: [],
    rowActions: [],
    pageSize: undefined,
    pageNumber: undefined,
    pageTotal: undefined,
  }),
  computed: {
  },
  created () {
 
  },
  async mounted () {
    this.tableData.cleanAndUpdate(this.trucks)

    const headers= TableConstants.trucksHeaders( i18n);
    this.tableHeaders.cleanAndUpdate( headers)
  },
  methods: {
  
    /**
     * actions del breadcrumb
    */
    ...mapActions("breadcrumbs", ["pushBread", "pullBread"]),
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),
    ...mapMutations('dialog', {
      openDialog: 'openDialog',
      closeDialog: 'closeDialog'
    }),
    /**
     * Resize de los split panel
     */
    resize () {
    },
    selectTruck (truck) {
      this.$emit('select-truck',truck);
    },
    clickRow(data){
      this.selectTruck(data)
    },
    cancel() {
      this.$emit('cancel');
    },


    getData () {
      this.loadingTable = true
    },
 
    notImplemented () {
      this.showSnackbar({ visible: true, text: this.$t('notImplemented'), timeout: 6000, style: SnackbarStyle.WARNING })
    },
  },
  watch: {

  }
}
