// import i18n from '@/i18n'
import { SnackbarStyle } from '@/constants/constants'
import { mapMutations, mapActions } from 'vuex'
import truckSelectorComponent from '@/components/smart-truck-dispatcher/TruckDispatch/truckSelector/truckSelectorComponent.vue'


export default {
  name: 'TruckDispatchComponent',
  components: {
    truckSelectorComponent
  },
  props: {
    front:undefined,
    fronts:undefined,
    selectedTruckToDispatch:undefined,
    fromHistory:false,//este parametro es para mostrar desde el historico sin permitir que el usuario realice acciones como despachar
  },
  data: () => ({
    showTrucksInFrontDialog:false,
    canva: null,
    canvaContainer:null,
    mouseXPosition: null,
    mouseXPositionPercentaje: null,
    truckMouseover:null,
    trucksToShowInModal: [],
    frontrandomkey:Math.ceil(Math.random() * 1000000),
  }),
  computed: {
  },
  created () {
    //agrego listener cuando la ventana cambia de tamaño para volver a dibujar el canva y hacerlo "responsive"
    window.addEventListener("resize", this.resizeEventHandler);
    //agrego listener para cuando el mouse se mueva sobre el canva, poder detectar si está arriba de un camion.
    

    // Si hay query params, los setea para incluirlos en la llamada al backend, de lo contrario, busca todas las reglas de vinculación
    if (this.$route.query) {
      this.queryPageSize = this.$route.query.pageSize ? Number(this.$route.query.pageSize) : 100
      this.queryPageNumber = this.$route.query.pageNumber ? Number(this.$route.query.pageNumber) : 1
    }
    // Valida que los qery params sean correctos y completos
    if ((this.queryPageSize && this.queryPageSize <= 0) || (this.queryPageNumber && this.queryPageNumber <= 0) || (this.queryPageNumber && !this.queryPageSize) || (!this.queryPageNumber && this.queryPageSize)) {
      this.showSnackbar({ visible: true, text: this.$t('error.invalidQueryParams'), timeout: 6000, style: SnackbarStyle.ERROR })
      this.queryPageSize = 100
      this.queryPageNumber = undefined
    }
  },
  async mounted () {
    
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    this.canva = document.getElementById("myCanva"+this.front.id )
    this.canvaContainer = document.getElementById("canvaContainer"+this.front.id );
    this.drawCanva();
  },
  methods: {
    /* Este metodo se activa cuando el mouse está sobre el elemento canva */
    setMousePosition(mouse){
      this.mouseXPosition = mouse.offsetX;
      this.mouseXPositionPercentaje = (this.mouseXPosition-this.margenIzquierdo) / (this.canva.width-(this.margenDerecho + this.margenIzquierdo)) * 100;
      this.truckMouseover = this.front.trucksInTravell.filter(truck => (truck.advancePercentage +1) > this.mouseXPositionPercentaje && (truck.advancePercentage -1) < this.mouseXPositionPercentaje)[0]
      this.drawCanva();
    },
    mouseLeaveCanva(){
      this.truckMouseover = null;
      this.drawCanva();
    },
    clickOnCanva () {
      if(this.truckMouseover)
      {
        this.iconClick(this.truckMouseover);
      }
    },
    resizeEventHandler(){
      //dibujar el canva y hacerlo "responsive"
      this.drawCanva();
    },
    
    drawCanva(){
      this.margenDerecho = 50//estos margenes los uso para que se pueda msotrar la etiqueta completa del nombre y tiempo del camion
      this.margenIzquierdo = 40;
      //tomo el tamaño del div contenedor del canva para copiar el tamaño en pixeles
      const canvaContainer = this.canvaContainer;
      if(!canvaContainer){
        return;
      }
      this.canva.width = canvaContainer.clientWidth-5;
      this.canva.height = canvaContainer.clientHeight-5;
      //comienza el dibujo
      const endLine = this.canva.width - this.margenDerecho;
      const startLine = this.margenIzquierdo ;
      const heightLine = 38;
      const ctx = this.canva.getContext("2d");
      ctx.clearRect(0, 0, this.canva.width, this.canva.height);

      //dibujo la linea principal
      ctx.beginPath();
        //le doy 20 pixeles de margen de cada lado
      ctx.moveTo(startLine,heightLine)
      ctx.lineTo(endLine, heightLine);
      ctx.stroke();
      //dibujo el primer circulo
      // const circle1 = new Path2D();
      // circle1.arc(startLine,heightLine, 6, 25, 0, 2 * Math.PI);      
      // ctx.stroke(circle1);

      const point1 = new Path2D();
      point1.arc(startLine,heightLine, 2, 25, 0, 2 * Math.PI);      
      ctx.fill(point1);

      //dibujo el circulo al final
      // const circle2 = new Path2D();
      // circle2.arc(endLine,heightLine, 6, 25, 0, 2 * Math.PI);      
      // ctx.stroke(circle2);

      const point2 = new Path2D();
      point2.arc(endLine,heightLine, 2, 25, 0, 2 * Math.PI);      
      ctx.fill(point2);
      //dibujo los camiones que están en camino
      this.drawTrucks(startLine,endLine, heightLine);

      this.frontrandomkey = Math.ceil(Math.random() * 1000000);
    },
    drawTrucks(startLine,endLine, heightLine){
      //dibujo cada camion
      this.front.trucksInTravell.forEach(myTruck => {
        let isMouseOver = false;
        if(this.truckMouseover){
          isMouseOver = this.truckMouseover.idThing === myTruck.idThing;
        }
          if(!isMouseOver){
            this.drawTruck(myTruck,startLine,endLine, heightLine,isMouseOver);
          }
      });
      //dibujar por ultimo el mouse over para que quede mas arriba
      if(this.truckMouseover){
        this.drawTruck(this.truckMouseover,startLine,endLine, heightLine,true);
      }
    },
    drawTruck(truck,startLine,endLine, heightLine, isMouseOver){
      //primero debo calcular la posicion del camion, y traducir eso al porcentaje del camino recorrido.
      const totalLenght = endLine-startLine;
      const truckPosition = (totalLenght * (truck.advancePercentage / 100)) + this.margenIzquierdo;

      const iconRadious = 7;
      let iconHeight = 10;
      if(isMouseOver){
        iconHeight = 15
      }
      const bigFontSize = 11;
      const smallFontSize = 9;

      const ctx = this.canva.getContext("2d");
      //seteo el color del camion
      ctx.strokeStyle =truck.color;
      ctx.fillStyle =truck.color;
      ctx.lineWidth =2;
      const truckIcon = new Path2D();
      //dibujo el arco de arriba del icono de camion
      truckIcon.arc(truckPosition,heightLine-iconHeight, 7, Math.PI, 2 * Math.PI); 
      if(truck.filled){
        ctx.fill(truckIcon);
      } 
      else{
        ctx.stroke(truckIcon);
      }    
      
      //dibujo el cono de abajo  del icono de camion
      ctx.beginPath();
      ctx.moveTo(truckPosition-iconRadious, heightLine-iconHeight);
      ctx.lineTo(truckPosition, heightLine);
      ctx.lineTo(truckPosition+iconRadious, heightLine-iconHeight);
      if(truck.filled){
        ctx.fill();
      } 
      else{
        ctx.stroke();
      }    
      //dibujo un punto en el medio del icono
      const point = new Path2D();
      point.arc(truckPosition,heightLine-iconHeight, 2, 25, 0, 2 * Math.PI);      
      if(truck.filled){
        ctx.fillStyle ="white";
      } 
      else{
        ctx.fillStyle =truck.color;
      }  
      ctx.fill(point);
      //dibujo la flecha dependiendo si el camion va o vuelve.
      if(truck.withArrow === true){
        ctx.fillStyle =truck.color;
        if(truck.isGoing)
        {
          const startArrowPosition = truckPosition + iconRadious + 3;
          const bottonArrowPosition = heightLine -5;
          const topArrowPosition = heightLine - iconHeight - iconRadious+3;
          const midleArrowPosition = heightLine -iconHeight;
          ctx.beginPath();
          ctx.moveTo(startArrowPosition, topArrowPosition);
          ctx.lineTo(startArrowPosition + 6, midleArrowPosition );
          ctx.lineTo(startArrowPosition, bottonArrowPosition );
          ctx.fill();
        }
        else{
          const startArrowPosition = truckPosition - iconRadious - 3;
          const bottonArrowPosition = heightLine -5;
          const topArrowPosition = heightLine - iconHeight - iconRadious+3;
          const midleArrowPosition = heightLine -iconHeight;
          ctx.beginPath();
          ctx.moveTo(startArrowPosition, topArrowPosition);
          ctx.lineTo(startArrowPosition - 6, midleArrowPosition );
          ctx.lineTo(startArrowPosition, bottonArrowPosition );
          ctx.fill();
        }
      } 

      //si tiene demora, dibujo el signo de exclamacion(derecha o izquieda para no tapar la flecha)    
      if(truck.desviationAlerted){
        const signFontSize = 18
        if(truck.isGoing){
          ctx.fillStyle = "red";
          ctx.font =signFontSize+"px Roboto";
          const singWidth = ctx.measureText('!').width;//para cuando el equipo no tiene equipo cañero
          ctx.fillText('!', truckPosition-iconRadious-singWidth-3, heightLine - 3 ); 
        }
        else{
          ctx.fillStyle = "red";
          ctx.font =signFontSize+"px Roboto";
          const singWidth = ctx.measureText('!').width;//para cuando el equipo no tiene equipo cañero
          ctx.fillText('!', truckPosition+iconRadious+singWidth-3, heightLine - 3 ); 
        }
      }  
      

      //Escribo las etiquetas del camion (numero y hora de salida)
      //primero le pongo un fondo de color oscuro al texto. 
      ctx.strokeStyle ="#beb5b5";
      ctx.fillStyle ="#beb5b5";
      //resto 4: 2 de margen izq + 2 que resto en el texto
      //resto 22: es el alto del texto + un margen + el radio del cono
      //sumo 4: 2 de margen izq + 2 de margen derecho
      ctx.font =bigFontSize+"px Roboto";
      const nonEtquipmentText = truck.caneEquipmentId? '':'S/E ';
      const SENumber = ctx.measureText(nonEtquipmentText).width;//para cuando el equipo no tiene equipo cañero
      const lenNumber = (ctx.measureText(truck.truckNumber.toString()).width) + SENumber;
      this.roundedRect(ctx,truckPosition-lenNumber -4, heightLine-iconHeight - 22, lenNumber+4, 14, 3);
      //escribo el texto restandole el tamaño del texto
      
      ctx.fillStyle = "black";
      ctx.fillText(nonEtquipmentText+truck.truckNumber.toString(), truckPosition-lenNumber-2, heightLine-iconHeight - bigFontSize ); //le resto 2 a truckPosition para dar margen derecho
      //escribo el texto del tiempo de despacho
      //primero le pongo un fondo de color claro al texto. 
      ctx.strokeStyle ="#ebebeb";
      ctx.fillStyle ="#ebebeb";
      const lenDate = ctx.measureText(truck.estimatedArriveTime.toString()).width;
      this.roundedRect(ctx,truckPosition, heightLine-iconHeight - 22, lenDate, 14, 3);

      ctx.fillStyle = "black";
      ctx.font =smallFontSize+"px Roboto";
      ctx.fillText(truck.estimatedArriveTime, truckPosition+3, heightLine-iconHeight - smallFontSize ); //le sumo 5 a truckPosition para dar margen izquierdo

    },
    roundedRect(ctx,x,y,width,height,radius){
      ctx.beginPath();
      ctx.moveTo(x,y+radius);
      ctx.lineTo(x,y+height-radius);
      ctx.quadraticCurveTo(x,y+height,x+radius,y+height);
      ctx.lineTo(x+width-radius,y+height);
      ctx.quadraticCurveTo(x+width,y+height,x+width,y+height-radius);
      ctx.lineTo(x+width,y+radius);
      ctx.quadraticCurveTo(x+width,y,x+width-radius,y);
      ctx.lineTo(x+radius,y);
      ctx.quadraticCurveTo(x,y,x,y+radius);
      ctx.fill();
   },
    /**
     * actions del breadcrumb
    */
    ...mapActions("breadcrumbs", ["pushBread", "pullBread"]),
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),
    ...mapMutations('dialog', {
      openDialog: 'openDialog',
      closeDialog: 'closeDialog'
    }),
    /**
     * Resize de los split panel
     */
    resize () {
    },
    dispatch () {
      this.$emit('dispatch-truck-open-dialog',this.front);
    },
    iconClick (object) {
      this.showTruckDialogfunction(object);
    },
    harvestClick (object) {
      this.showHarvestDialog(object);
    },
    
    pinClick (front) {
      front.pinned = !front.pinned;
      this.$emit('pin-front',front.id);
      this.sortData();
    },
    showHarvestDialog (infoHarvestModal) {
      this.$emit('show-harvest-dialog-function',infoHarvestModal,this.front);
    },
    showTruckDialogfunction (infoTruckModal) {
      this.$emit('show-truck-dialog-function',infoTruckModal,this.front);
    },
    openTrucksInFront(){
      if(this.front.trucksInFront.length > 1){
        this.showTrucksInFrontDialog = true;
        this.trucksToShowInModal = this.front.trucksInFront;
      }
      else if(this.front.trucksInFront.length === 1){
        const truck = this.front.trucksInFront[0]
        this.$emit('show-truck-dialog-function',truck,this.front);
      }
    },
    openTrucksRecentDispatched(){
      if(this.front.trucksRecentDispatched.length > 1){
        this.showTrucksInFrontDialog = true;
        this.trucksToShowInModal = this.front.trucksRecentDispatched;
      }
      else if(this.front.trucksRecentDispatched.length === 1){
        const truck = this.front.trucksRecentDispatched[0]
        this.$emit('show-truck-dialog-function',truck,this.front);
      }
    },
    selectTruckInDialogSelector(truck){
      this.showTrucksInFrontDialog = false;
      this.trucksToShowInModal = [];
      this.$emit('show-truck-dialog-function',truck,this.front);
    },
    openFrontDialog(){
      this.$emit('show-front-dialog-function',this.front);
    },
    cancel() {
      this.showTrucksInFrontDialog = false;
      this.trucksToShowInModal = [];
    },
    /**
     * Se ejecuta cuando cambian los datos de la tabla (por búsqueda, filtrado, agregar/quitar columnas, etc.)
     * @param {*} newData
     */
    dataChangeEventHandler () {
    },

    getData () {
      this.loadingTable = true
    },
    sortData() {       
      this.$emit('sort-data');     
    },
    notImplemented () {
      this.showSnackbar({ visible: true, text: this.$t('notImplemented'), timeout: 6000, style: SnackbarStyle.WARNING })
    },
  },
  watch: {
    front () {
      if (this.front) {
        this.drawCanva();
      }
    }
  }
}
